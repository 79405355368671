import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const req_params = JSON.parse(localStorage?.getItem("req_params")) || {};

let headers = {
  "Content-Type": "application/json",
};

if (user && user.token) {
  headers["Authorization"] = `Bearer ${user.token}`;
  headers["slug"] = req_params?.slug || '';
  headers["companyid"] = req_params?.company_id || '';
  headers["userid"] = req_params?.user_id || '';
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const createAxiosInstance = (customHeaders = {}) => {
  return axios.create({
    baseURL: BASE_URL,
    headers: { ...headers, ...customHeaders },
  });
};

const instance = createAxiosInstance();
const multipartInstance = createAxiosInstance({ "Content-Type": "multipart/form-data" });

instance.interceptors.request.use(
  (request) => {
    // Any custom logic before sending the request can go here
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    const err = {
      url: error?.toJSON().config.baseURL,
      body: JSON.parse(error?.toJSON().config.data),
      message: error?.response?.data?.message,
      code: error?.response?.status,
    };

    return Promise.reject(err);
  }
);

export { instance as axios, multipartInstance as fileAxios };
